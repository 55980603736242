/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />

$(document).ready(() => {
    // Braeburn Menu styling
    $('.widget-aside-second .dropdown.active').each(function () {
        if ($(this).find('.active').length > 0) {
            $(this).addClass("open");
            $(this).children('.dropdown-bar').children('.expand-arrow').children('.fa-angle-down, .fa-angle-right').toggleClass('fa-angle-down fa-angle-right');
        }
    });

    $('.menu .expand-arrow-companion').css("cursor", "pointer");

    $('.menu .dropdown-bar div').on("mouseenter", function () {
        if ($(this).hasClass("open-on-hover")) {
            $(this).parent().addClass("open");
            $(this).parent().parent().addClass("open");
        }
    });

    $('.menu .shopping-cart-button.hover-enabled').on("mouseover", function () {
        $(this).addClass("open");
        $(this).children('.dropdown-bar').addClass("open");
    });

    $('.menu .dropdown-menu').on("mouseenter", function () {
        $(this).parent().addClass("open");
        $(this).siblings('.dropdown-bar').addClass("open");
    });
    $('.menu .dropdown-bar div').on("mouseleave", function () {
        if ($(this).hasClass("open-on-hover") && !$(this).hasClass("leave-open")) {
            $(this).parent().removeClass("open");
            $(this).parent().parent().removeClass("open");
        }
    });

    var timeOut;

    $('.menu .shopping-cart-button.hover-enabled').on("mouseleave", function () {
        if (!$(this).hasClass("leave-open")) {
            timeOut = setTimeout(() => {
                $(this).removeClass("open");
                $(this).children('.dropdown-bar').removeClass("open");
            }, 500);
        }
    });

    $('.mini-cart-area').on("mouseenter", () => {
        clearTimeout(timeOut);
    });

    $('.menu .dropdown-menu').on("mouseleave", function () {
        if (!$(this).hasClass("leave-open")) {
            $(this).parent().removeClass("open");
            $(this).siblings('.dropdown-bar').removeClass("open");
        }
    });
});

